html,
body {
    height: 100%;
    margin: 0;
    padding: 0;
}
a:visited {
    text-decoration: none;
    /* possible color error */
    /* color: inherit; */
}
a:hover {
    text-decoration: none;
    /* color: inherit; */
}
a:focus {
    text-decoration: none;
    /* color: inherit; */
}
a:hover,
a:active {
    text-decoration: none;
    /* color: inherit; */
}
.social-share-button {
    position: relative;
    top: 0;
    transition: top ease 0.5s;
    font-size: 24px;
}
.social-share-button:hover {
    top: -5px;
}
.social-container {
    display: flex;
    flex-direction: row;
}
.tumblr-button {
    position: relative;
    top: 0;
    transition: top ease 0.5s;
    font-size: 24px;
}
.tumblr-button:hover {
    top: -5px;
}
.share-writing {
    display: flex;
    justify-content: center;
}
